import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import useData from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import TimeType from "../components/custom-type/TimeType";
import NumberType from "../components/custom-type/NumberType";
import { regExValidations } from "../data/consts";
import Big from "big.js";
import { onlyNumbersWithDot } from "../utils/helpers";

const CreateTldForm = () => {
    const { fetchData, postData } = useData();
    const { showError, showSuccess } = useMessage();
    const navigate = useNavigate();

    const initialValues = {
        tldName: "",
        dropTimeStart: "",
        dropTimeEnd: "",
        registry: "",
        successFee: "",
        regFee: "",
        relativeAucEnd: "",
        calculable: false,
        restricted: false,
        adminOnly: false,
        dropStatus: "",
        whoisArchive: "",
        whoisField: "",
    };

    const validationSchemaNew = {
        tldName: yup
            .string()
            .required("Required Field")
            .matches(regExValidations.tld, "Invalid TLD Name"),
        registry: yup.string().trim().required("Required Field"),
        dropTimeStart: yup
            .string()
            .required("Required Field")
            .length(8, "Required Field"),
        dropTimeEnd: yup
            .string()
            .required("Required Field")
            .length(8, "Required Field"),
        relativeAucEnd: yup
            .string()
            .required("Required Field")
            .length(8, "Required Field"),
        successFee: yup.string().nullable().required("Required Field"),
        regFee: yup.string().nullable().required("Required Field"),
        calculable: yup.boolean().required("Required Field"),
        dropStatus: yup
            .string()
            .test("conditional-required", "Required Field", function (value) {
                if (this.parent.calculable === true) {
                    return !!value;
                }
                return true;
            }),
        whoisField: yup
            .string()
            .test("conditional-required", "Required Field", function (value) {
                if (this.parent.calculable === true) {
                    return !!value;
                }
                return true;
            }),
        whoisArchive: yup
            .string()
            .test("conditional-required", "Required Field", function (value) {
                if (this.parent.calculable === true) {
                    return !!value;
                }
                return true;
            }),
    };

    const validationSchema = yup.object(validationSchemaNew);

    const [registries, setRegistries] = useState<string[]>([]);

    const [successFee, setSuccessFee] = useState<string>("");
    const [regFee, setRegFee] = useState<string>("");

    const [calculable, setCalculable] = useState<boolean>(false);
    const [dropTimeStart, setDropTimeStart] = useState<string>("");
    const [dropTimeEnd, setDropTimeEnd] = useState<string>("");
    const [relativeAucEnd, setRelativeAucEnd] = useState<string>("");

    const loadRegistries = async () => {
        try {
            const response: any = await fetchData("/registry");
            if (response?.data) {
                setRegistries(
                    Array.from(
                        response.data.map(
                            (singleRegistry: any) => singleRegistry.registry,
                        ),
                    ),
                );
            } else {
                setRegistries([]);
            }
        } catch (error) {
            setRegistries([]);
        }
    };

    useEffect(() => {
        loadRegistries();
    }, []);

    useEffect(() => {
        formik.setFieldValue("dropTimeStart", dropTimeStart);
    }, [dropTimeStart]);

    useEffect(() => {
        formik.setFieldValue("dropTimeEnd", dropTimeEnd);
    }, [dropTimeEnd]);

    useEffect(() => {
        formik.setFieldValue("successFee", successFee);
    }, [successFee]);

    useEffect(() => {
        formik.setFieldValue("regFee", regFee);
    }, [regFee]);

    useEffect(() => {
        formik.setFieldValue("relativeAucEnd", relativeAucEnd);
    }, [relativeAucEnd]);

    useEffect(() => {
        formik.setFieldValue("calculable", calculable);
    }, [calculable]);

    interface TldConfig {
        updatedWhoisFieldName: string | null;
        whoisStringToArchive: string | null;
        relativeAuctionEnd: string;
        restricted: boolean;
        adminOnly: boolean;
    }

    interface CreateTldData {
        name: string;
        dropTimeStart: string;
        dropTimeEnd: string;
        registry: string;
        beforeDropStatus: string;
        calculable: boolean;
        successFee: string;
        registrarPrice: [
            {
                registrar: string;
                price: string;
            },
        ];
        restricted: boolean;
        adminOnly: boolean;
        configs: TldConfig;
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values) {
            try {
                const createTldData: CreateTldData = {
                    name: values.tldName,
                    dropTimeStart: values.dropTimeStart,
                    dropTimeEnd: values.dropTimeEnd,
                    beforeDropStatus: "",
                    registry: values.registry,
                    calculable: values.calculable,
                    successFee: new Big(values.successFee).toString(),
                    registrarPrice: [
                        {
                            registrar: "namerider",
                            price: new Big(values.regFee).toString(),
                        },
                    ],
                    restricted: values.restricted,
                    adminOnly: values.adminOnly,
                    configs: {
                        updatedWhoisFieldName: null,
                        whoisStringToArchive: null,
                        relativeAuctionEnd: values.relativeAucEnd,
                        restricted: values.restricted,
                        adminOnly: values.adminOnly,
                    },
                };

                if (calculable) {
                    createTldData.beforeDropStatus = values.dropStatus;
                    createTldData.configs.updatedWhoisFieldName =
                        values.whoisField;
                    createTldData.configs.whoisStringToArchive =
                        values.whoisArchive;
                }

                const response: any = await postData(
                    "/tld/create",
                    createTldData,
                );

                if (response?.success) {
                    showSuccess("TLD created successfully");
                    navigate("/admin/tlds");
                } else {
                    if (/duplicate/.test(response.message)) {
                        showError("TLD already exists");
                    } else {
                        showError("TLD creation Failed");
                    }
                }
            } catch (e: any) {
                if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError("TLD creation failed");
                }
            }
        },
    });

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
        },
        inputGroup: {
            my: { md: 0, xs: 1 },
            ml: { md: 5, xs: 0 },
        },
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Grid container spacing={5}>
                {/* First Column */}
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={1} direction="column">
                        {/* First Row in First Column */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Name</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TextField
                                        value={formik.values.tldName}
                                        sx={{
                                            "&::placeholder": {
                                                textTransform: "uppercase",
                                            },
                                        }}
                                        onChange={(event) => {
                                            const inputValue =
                                                event.target.value.toLowerCase();
                                            formik.setFieldValue(
                                                "tldName",
                                                inputValue,
                                            );
                                        }}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.tldName &&
                                            formik.touched.tldName
                                        }
                                    />
                                    {formik.errors.tldName && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.tldName &&
                                                formik.errors.tldName}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Second Row in First Column */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Registry</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <Select
                                        id="registry"
                                        value={formik.values.registry}
                                        onChange={(event) =>
                                            formik.setFieldValue(
                                                "registry",
                                                event.target.value as string,
                                            )
                                        }
                                        displayEmpty
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.registry &&
                                            formik.touched.registry
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "150px",
                                                    overflowY: "auto",
                                                },
                                            },
                                        }}
                                    >
                                        {registries.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formik.errors.registry && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.registry &&
                                                formik.errors.registry}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Third Row in First Column */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Relative Auction End</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TimeType
                                        timeSelected={relativeAucEnd}
                                        onTimeType={setRelativeAucEnd}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.relativeAucEnd &&
                                            formik.touched.relativeAucEnd
                                        }
                                        isFreeze={false}
                                    />
                                    {formik.errors.relativeAucEnd && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.relativeAucEnd &&
                                                formik.errors
                                                    .relativeAucEnd}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Fourth row */}
                        {calculable && (
                            <Grid item>
                                <Stack sx={{ height: "98px" }}>
                                    <InputLabel>Before Drop Status</InputLabel>
                                    <FormControl sx={styles.formControlStyle}>
                                        <TextField
                                            value={formik.values.dropStatus}
                                            sx={{
                                                "&::placeholder": {
                                                    textTransform: "uppercase",
                                                },
                                            }}
                                            onChange={(event) => {
                                                const filteredValue =
                                                    event.target.value.replace(
                                                        /[^a-zA-Z]/g,
                                                        "",
                                                    );
                                                formik.setFieldValue(
                                                    "dropStatus",
                                                    filteredValue,
                                                );
                                            }}
                                            // @ts-expect-error error
                                            error={
                                                formik.errors.dropStatus &&
                                                formik.touched.dropStatus
                                            }
                                        />
                                        {formik.errors.dropStatus && (
                                            <FormHelperText
                                                sx={{ mx: 0 }}
                                                error
                                            >
                                                {" "}
                                                {formik.touched.dropStatus &&
                                                    formik.errors
                                                        .dropStatus}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <FormControl sx={styles.formControlStyle}>
                                    <Box
                                        sx={{ marginLeft: "2px" }}
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formik.values.restricted
                                                    }
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            "restricted",
                                                            event.target
                                                                .checked,
                                                        );
                                                    }}
                                                    sx={{
                                                        transform:
                                                            "scale(0.85)",
                                                    }}
                                                />
                                            }
                                            label="Restricted"
                                            sx={{ marginRight: "15%" }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formik.values.adminOnly
                                                    }
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            "adminOnly",
                                                            event.target
                                                                .checked,
                                                        );
                                                    }}
                                                    sx={{
                                                        transform:
                                                            "scale(0.85)",
                                                    }}
                                                />
                                            }
                                            label="Admin Only"
                                        />
                                    </Box>
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Second Column */}
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={1} direction="column">
                        {/* First Row in First Column */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Drop Time Start</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TimeType
                                        timeSelected={dropTimeStart}
                                        onTimeType={setDropTimeStart}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.dropTimeStart &&
                                            formik.touched.dropTimeStart
                                        }
                                        isFreeze={false}
                                    />
                                    {formik.errors.dropTimeStart && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.dropTimeStart &&
                                                formik.errors
                                                    .dropTimeStart}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Second Row */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Success Fee</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <NumberType
                                        currentValue={successFee}
                                        onCurrencyType={setSuccessFee}
                                        onChange={(event) => {
                                            const filteredValue =
                                                onlyNumbersWithDot(event);
                                            formik.setFieldValue(
                                                "successFee",
                                                filteredValue,
                                            );
                                            setSuccessFee(filteredValue);
                                        }}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.successFee &&
                                            formik.touched.successFee
                                        }
                                    />
                                    {formik.errors.successFee && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.successFee &&
                                                formik.errors.successFee}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Third Row */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Calculable</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <RadioGroup
                                        row
                                        value={calculable}
                                        onChange={(event) => {
                                            setCalculable(
                                                event.target.value === "true",
                                            );
                                        }}
                                        sx={{
                                            marginTop: "3.2px",
                                            marginBottom: "7px",
                                        }}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                    {formik.errors.calculable && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.calculable &&
                                                formik.errors.calculable}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Fourth row */}
                        {calculable && (
                            <Grid item>
                                <Stack sx={{ height: "98px" }}>
                                    <InputLabel>
                                        Updated Whois Field Name
                                    </InputLabel>
                                    <FormControl sx={styles.formControlStyle}>
                                        <TextField
                                            value={formik.values.whoisField}
                                            sx={{
                                                "&::placeholder": {
                                                    textTransform: "uppercase",
                                                },
                                            }}
                                            onChange={(event) => {
                                                const filteredValue =
                                                    event.target.value.replace(
                                                        /[^a-zA-Z]/g,
                                                        "",
                                                    );
                                                formik.setFieldValue(
                                                    "whoisField",
                                                    filteredValue,
                                                );
                                            }}
                                            // @ts-expect-error error
                                            error={
                                                formik.errors.whoisField &&
                                                formik.touched.whoisField
                                            }
                                        />
                                        {formik.errors.whoisField && (
                                            <FormHelperText
                                                sx={{ mx: 0 }}
                                                error
                                            >
                                                {" "}
                                                {formik.touched.whoisField &&
                                                    formik.errors
                                                        .whoisField}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* Third Column */}
                <Grid item xs={12} sm={12} md={4}>
                    <Grid container spacing={1} direction="column">
                        {/* First Row in third Column */}
                        <Grid item>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Drop Time End</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TimeType
                                        timeSelected={dropTimeEnd}
                                        onTimeType={setDropTimeEnd}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.dropTimeEnd &&
                                            formik.touched.dropTimeEnd
                                        }
                                        isFreeze={false}
                                    />
                                    {formik.errors.dropTimeEnd && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.dropTimeEnd &&
                                                formik.errors.dropTimeEnd}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Second Row*/}
                        <Grid item sx={{ mb: "106px" }}>
                            <Stack sx={{ height: "98px" }}>
                                <InputLabel>Reg Fee</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <NumberType
                                        currentValue={regFee}
                                        onCurrencyType={setRegFee}
                                        onChange={(event) => {
                                            const filteredValue =
                                                onlyNumbersWithDot(event);
                                            formik.setFieldValue(
                                                "regFee",
                                                filteredValue,
                                            );
                                            setRegFee(filteredValue);
                                        }}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.regFee &&
                                            formik.touched.regFee
                                        }
                                    />
                                    {formik.errors.regFee && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.regFee &&
                                                formik.errors.regFee}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Fourth row */}
                        {calculable && (
                            <Grid item>
                                <Stack sx={{ height: "98px" }}>
                                    <InputLabel>
                                        Whois String to Archive
                                    </InputLabel>
                                    <FormControl sx={styles.formControlStyle}>
                                        <TextField
                                            value={formik.values.whoisArchive}
                                            sx={{
                                                "&::placeholder": {
                                                    textTransform: "uppercase",
                                                },
                                            }}
                                            onChange={(event) => {
                                                const filteredValue =
                                                    event.target.value.replace(
                                                        /[^a-zA-Z]/g,
                                                        "",
                                                    );
                                                formik.setFieldValue(
                                                    "whoisArchive",
                                                    filteredValue,
                                                );
                                            }}
                                            // @ts-expect-error error
                                            error={
                                                formik.errors.whoisArchive &&
                                                formik.touched.whoisArchive
                                            }
                                        />
                                        {formik.errors.whoisArchive && (
                                            <FormHelperText
                                                sx={{ mx: 0 }}
                                                error
                                            >
                                                {" "}
                                                {formik.touched.whoisArchive &&
                                                    formik.errors
                                                        .whoisArchive}{" "}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item xs={12} mt={8} textAlign="right">
                            <Button
                                variant="contained"
                                type="submit"
                                style={{
                                    width: "100px",
                                    height: "40px",
                                }}
                            >
                                {"Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateTldForm;
